import { Table, Dropdown } from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import { CSVLink } from "react-csv";
import queryString from "query-string";
import { useQuery } from "react-query";
import { useEffect, useState, useRef } from "react";
import { format, parse } from "date-fns";
import { useOutletContext } from "react-router-dom";
import currency from "currency.js";

import { ExportIcon, ExcelIcon, PDFIcon, FilterTwoIcon } from "../../Icons";
import NoTableItem from "../../utils/NoTableItem";
import { useDownloadExcel } from "../../../hooks/useDownloadExcel";
import { useAuth } from "../../../hooks/useAuth";
import useDebounce, { useScrollTop } from "../../../utils/hooks";
import ModalLoader from "../../utils/ModalLoader";
import { fetchActionsUtil } from "../../../utils/helpers";

function Debtor() {
  useScrollTop();
  const {
    queryParams,
    setQueryParams,
    showFilter,
    setShowFilter,
  } = useOutletContext();

  const initialFilterParams = {};

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  const { backendUrl } = useAuth();

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const [filterParams] = useState({
    ...initialFilterParams,
    ...queryParams,

    // Get the date in url and parse so date picker can use it
    date: queryParams?.date
      ? parse(queryParams?.date, "yyyy-MM-dd", new Date())
      : "",
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const {
    data = {
      count: 0,
      Debitors: [],
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    ["FETCH_DEBOTORS", queryParams],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/customers/Debtors-sales-rep?${queryString.stringify(
          queryParams
        )}`,
        "GET"
      ),
    {
      keepPreviousData: true,
    }
  );
  // console.log(data);
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/customers/Debtors-sales-rep?${queryString.stringify(
        rest
      )}`,
      "GET"
    );
    const company = exData?.company?.CompName;

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";

    exData = exData?.Debitors?.map((el, i) => [
      i + 1,
      el.Cust_ID,
      el.LastName,
      el.TransType,
      currency(el.Credit, {
        symbol: "",
      }).format(),
      currency(el.Debit, {
        symbol: "",
      }).format(),

      currency(el.balance, {
        symbol: "",
      }).format(),
      el.Branch,
      el.Dept,
    ]);

    exData = [
      [company],
      ["Rep.Sales/Debtors Report"],
      [date],
      [""],
      [
        "S/N",
        "Cust ID",
        "Customer Name",
        "Transaction Type",
        "Credit",
        "Debit",
        "Balance",
        "Branch",
        "Department",
      ],
      ...exData,
    ];
    console.log(exData);
    setExcelData(exData);
  };

  return (
    <>
      <div className="absolute-title">
        <h1> Sales Reps Analysis</h1>
      </div>

      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className="content-body">
              <header>
                <h1 className="d-flex align-items-center gap-3">
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Sales/Payment Report
                  <button
                    title="Refresh"
                    onClick={() => refetch()}
                    className="btn text-primary"
                  >
                    <CachedIcon />
                  </button>
                </h1>

                <div className="actions">
                  <CSVLink
                    className="btn print d-none"
                    filename={`Rep.Sales/Debtors Report(${format(
                      new Date(),
                      "dd-MMM-yyyy hh:mm:ss a"
                    )}).csv`}
                    data={excelData}
                    ref={CSVLinkRef}
                  />

                  {/* <button /> */}
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn print"
                      disabled={isfetchingExcel}
                      bsPrefix=""
                    >
                      Export
                      <ExportIcon color="#008000" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item
                        as="div"
                        onClick={onDownloadExcelData}
                        className="p-cursor"
                      >
                        Excel <ExcelIcon color="#008000" />
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/customers/pdf/Debtors-sales-rep?${queryString.stringify(
                            rest
                          )}`}
                          target="blank"
                        >
                          PDF
                          <PDFIcon color="#ff0000" />
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </header>

              <div className="px-md-4">
                <Table
                  responsive
                  borderless
                  striped
                  className="product-table mt-4"
                >
                  <thead>
                    <tr>
                      <th>Cust ID</th>
                      <th>Customer Name</th>
                      <th>Transaction Type</th>
                      <th>Credit</th>
                      <th>Debit</th>
                      <th>Balance</th>
                      <th>Branch</th>
                      <th>Department</th>
                    </tr>
                  </thead>
                  <tbody className="blue-hover">
                    {data?.Debitors &&
                      data?.Debitors?.map((el, index) => (
                        <tr key={index}>
                          <td>{el.Cust_ID}</td>
                          <td>{el.LastName}</td>
                          <td>{el.TransType}</td>
                          <td>
                            {currency(el.Credit, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {currency(el.Debit, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {currency(el.balance, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>{el.Branch}</td>
                          <td>{el.Dept}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {!isFetching && isSuccess && data?.Debitors?.length < 1 ? (
                  <NoTableItem queryParams={queryParams} />
                ) : null}
              </div>

              {/* {data?.customerLedgers?.length > 0 ? (
                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>

                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={data.count / queryParams.limit}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              ) : (
                <div className="py-2" />
              )} */}
            </div>
          </div>
        </div>
        <ModalLoader show={isfetchingExcel} />
      </main>
    </>
  );
}

export default Debtor;
