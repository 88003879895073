import currency from "currency.js";
import { format, startOfMonth } from "date-fns";
import { useQuery } from "react-query";
import "../../assets/scss/dashboard.scss";
import { useAuth } from "../../hooks/useAuth";
import {
  ArchiveFillIcon,
  ArrowDownGrowthIcon,
  ArrowUpGrowthIcon,
  FileListIcon,
  LineChartIcon,
} from "../Icons";
import CustomerBalance from "./CustomerBalance";
import RevenueVsExpenses from "./RevenueVsExpenses";
import SalesOverView from "./SalesOverview";
import TopSellingProduct from "./TopSellingProduct";
import TopEnquireProduct from "./TopEnquireProduct";

export default function Dashboard() {
  const { backendUrl } = useAuth();
  const initialFilterParams = {
    startDate: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
  };

  //  console.log(currency(8000.0).distribute(12));

  const getStats = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/items/dashboard-stats`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      //  credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    let { data } = await response.json();

    return data;
  };

  const { error, data, isSuccess, refetch, isFetching } = useQuery(
    ["DASHBOARD_STAT"],
    () => getStats(),
    {}
  );

  return (
    <div>
      <div className="dashboard-content">
        <section className="stats">
          <div className="stat">
            <div className="icon">
              <FileListIcon />
            </div>
            <div className="details">
              <p>
                {data?.totalSales
                  ? currency(data?.totalSales, {
                      symbol: "",
                      precision: 0,
                    }).format()
                  : "..."}
              </p>
              <span>Total Sales</span>
            </div>
          </div>

          <div className="stat">
            <div className="icon icon-1">
              <ArrowUpGrowthIcon />
            </div>
            <div className="details">
              <p>
                {data?.totalSalesFigures
                  ? currency(data?.totalSalesFigures, {
                      symbol: "₦",
                    }).format()
                  : "..."}
              </p>
              <span>Total Sales</span>
            </div>
          </div>

          <div className="stat">
            {/*  <div className="icon icon-2">
              <ArrowDownGrowthIcon />
            </div> */}
            <div className="icon icon-1">
              <ArrowUpGrowthIcon />
            </div>
            <div className="details">
              <p>
                {data?.totalProfit
                  ? currency(data?.totalProfit, {
                      symbol: "₦",
                      precision: 0,
                    }).format()
                  : "..."}
              </p>
              <span>Total Profit</span>
            </div>
          </div>

          <div className="stat">
            <div className="icon icon-3">
              <LineChartIcon />
            </div>
            <div className="details">
              <p>+0%</p>
              <span>Sales Growth</span>
            </div>
          </div>
          <div className="stat">
            <div className="icon icon-4">
              <ArchiveFillIcon />
            </div>
            <div className="details">
              <p>
                {data?.itemOutofStock
                  ? currency(data?.itemOutofStock, {
                      symbol: "",
                      precision: 0,
                    }).format()
                  : "..."}
              </p>
              <span>Item out of Stock</span>
            </div>
          </div>
        </section>

        <section className="charts">
          <RevenueVsExpenses />
          <SalesOverView />
        </section>

        {/* <section className="infos">
          <CustomerBalance />
          <TopSellingProduct />
          <TopEnquireProduct />
        </section> */}
        <section className="row">
          <div className="col-6 all-cards">
            <CustomerBalance />
          </div>
          <div className="col-3 all-cards">
            <TopSellingProduct />
          </div>
          <div className="col-3 all-cards">
            <TopEnquireProduct />
          </div>
        </section>
      </div>
    </div>
  );
}
