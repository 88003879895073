import { Modal, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useMutation } from "react-query";

import { fetchActionsUtil } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";

function MakeCutomerModal({
  openMakeCutomer,
  setOpenMakeCutomer,
  Cust_ID,
  mainRefetch,
}) {
  const { backendUrl } = useAuth();

  const createCustomerMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/customers/make-customer`,
        "PUT",
        "",
        payload
      ),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        setOpenMakeCutomer(false);
        mainRefetch();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      TransType: "Walk In",
    },
    validationSchema: yup.object().shape({
      TransType: yup.string().required(),
    }),
    onSubmit: async (values) => {
      const payload = {
        ...values,
        Cust_ID,
      };
      createCustomerMutation.mutate(payload);
      console.log(payload);
    },
  });

  return (
    <Modal
      show={openMakeCutomer}
      onHide={() => setOpenMakeCutomer(false)}
      centered
      animation
      enforceFocus
      size="md"
    >
      <Modal.Body>
        <div>
          <div className="d-flex justify-content-between">
            <h6>Make Customer</h6>
            <div
              role="button"
              className="text-lg"
              onClick={() => setOpenMakeCutomer(false)}
            >
              x
            </div>
          </div>
          {/* <p className="text-muted">
            Create a new plant and assign storage bins to it
          </p> */}
        </div>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <div>
            <Form.Group className="my-4">
              <Form.Label className="mb-2">Customer Type</Form.Label>
              <div className="d-flex gap-3 justify-content-between">
                <Form.Check
                  inline
                  label="Walk In"
                  value="Walk In"
                  name="TransType"
                  type="radio"
                  checked={formik.values.TransType === "Walk In"}
                  onChange={formik.handleChange}
                />

                <Form.Check
                  inline
                  label="Wholesaler"
                  value="Wholesaler"
                  name="TransType"
                  type="radio"
                  checked={formik.values.TransType === "Wholesaler"}
                  onChange={formik.handleChange}
                />

                <Form.Check
                  inline
                  label="Distributor"
                  value="Distributor"
                  name="TransType"
                  type="radio"
                  checked={formik.values.TransType === "Distributor"}
                  onChange={formik.handleChange}
                />
              </div>
              <Form.Control.Feedback type="invalid">
                {formik.errors.TransType}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <Button onClick={() => formik.submitForm()}>Save</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default MakeCutomerModal;
