import currency from "currency.js";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState, useMemo } from "react";
import { Table } from "react-bootstrap";
import { useQuery } from "react-query";
// import { services } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import useDebounce from "../../utils/hooks";
import "./../../assets/scss/scoped/items-table.scoped.scss";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import { paginationOptions, fetchActionsUtil } from "../../utils/helpers";

export default function PlantItemTable({
  handleSelectedItem,
  hideItemsNotInStock = false,
  showWarehouseDamage = false,
}) {
  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 100,
    q: "",
  });

  const debouncedqueryParams = useDebounce(queryParams, 500);

  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  const fetchPlantDamages = async (queryParams, ware) => {
    // await waitFor(5000);
    const data = await fetchActionsUtil(
      `${backendUrl}/api/warehouse/plant-damages/${ware}?&${queryString.stringify(
        queryParams
      )}`,
      "GET"
    );
    return data?.data;
  };

  const fetchWareHouseDamages = async (queryParams, ware) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/warehouse/damages/${
        ware.whID
      }?&${queryString.stringify(queryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const { data = { items: [] } } = useQuery(
    ["GET_PLANTS_ITEMS", { debouncedqueryParams, wareshouseLocal }],
    () =>
      showWarehouseDamage
        ? fetchWareHouseDamages(debouncedqueryParams, wareshouseLocal)
        : fetchPlantDamages(queryParams, wareshouseLocal?.whID),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const allItems = useMemo(() => {
    return [...new Map(data?.items?.map((d) => [d["Barcode"], d])).values()];
  }, [data?.items]);

  // console.log(data);
  return (
    <div className="items-table-area rounded">
      <div className="search-area m-3">
        <MagnifyIcon />
        <input
          className="form-control search-input"
          name="q"
          value={queryParams.q}
          onChange={(e) =>
            setQueryParams({
              ...queryParams,
              page: 1,
              [e.target.name]: e.target.value,
            })
          }
          placeholder="Search item..."
          autoFocus
          autoComplete="off"
          type={"text"}
        />
      </div>

      <div className="content">
        <Table borderless hover striped className="product-table">
          <thead className="sticky text-nowrap">
            <tr>
              <th scope="col">BarCode</th>
              <th scope="col">Item Name</th>
              <th scope="col">Unit Cost</th>
              <th scope="col">Unit Price</th>
            </tr>
          </thead>
          <tbody>
            {allItems &&
              allItems

                .filter((el) =>
                  hideItemsNotInStock ? Number(el.Quantity) > 0 : el
                )
                .map((el, index) => (
                  <tr
                    className="p-cursor"
                    key={index}
                    onClick={() => handleSelectedItem && handleSelectedItem(el)}
                  >
                    <td>{el.Barcode}</td>
                    <td title={el?.Quantity}>{el.Item_Name}</td>
                    <td>
                      {currency(el.Unit_Cost, {
                        precision: 2,
                        symbol: "",
                      }).format()}
                    </td>
                    <td>
                      {currency(el.UnitPrice, {
                        precision: 2,
                        symbol: "",
                      }).format()}
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </div>

      <div className="d-flex justify-content-between px-3 my-3 align-items-center pagination">
        <p className="m-0">
          Showing {data?.startIndex + 1} to{" "}
          {data?.endIndex <= allItems.length ? data?.endIndex : allItems.length}{" "}
          of {allItems.length} entries
        </p>

        <ReactPaginate
          {...paginationOptions}
          pageCount={Math.ceil(allItems.length / queryParams.limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={0}
          onPageChange={({ selected }) => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            setQueryParams({
              ...queryParams,
              page: selected + 1,
            });
          }}
          forcePage={queryParams.page - 1}
        />
      </div>
    </div>
  );
}
