import { useState } from "react";
import { Modal } from "react-bootstrap";
import queryString from "query-string";
import { useQuery, useMutation } from "react-query";
import ReactPaginate from "react-paginate";
import { format } from "date-fns";
import CachedIcon from "mdi-react/CachedIcon";
import { toast } from "react-toastify";

import useDebounce from "../../utils/hooks";
import {
  paginationOptions,
  scrollToTop,
  fetchActionsUtil,
} from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import ModalLoader from "../utils/ModalLoader";
import NoTableItem from "./../utils/NoTableItem";

export default function WayBillDatesModal({ onHide, show, salesID }) {
  const initialFilterParams = {
    showAll: false,
  };

  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [serchingItems, setSerchingItems] = useState({
    date_Check: "",
    TransactionID: "",
  });
  const debouncedqueryParams = useDebounce(queryParams, 500);

  const { data = { salesHistorydates: [] }, isLoading, refetch } = useQuery(
    ["GET_SALES_HISTORY_DATES", { debouncedqueryParams, salesID }],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/invoice/sale-dates/${salesID}?${queryString.stringify(
          debouncedqueryParams
        )}`,
        "GET"
      ),
    {
      enabled: true,
      keepPreviousData: false,
    }
  );

  //   console.log(data);

  const updateSupplyMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/invoice/pdf/supply-waybill`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: (updatedItem) => {
        console.log(updatedItem);
      },
      onError: ({ message }) => {
        console.log(message);
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  return (
    <Modal
      show={show}
      onHide={() => onHide()}
      dialogClassName="requisition-details-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="d-flex align-items-baseline">
            <span className="h5 fw-bold">Supply Dates</span>
            <span onClick={() => refetch()} className="btn text-primary">
              <CachedIcon />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 d-flex flex-column">
        <div className="content px-1 pb-4 pe-2 flex-grow-1">
          <div className="row">
            {data?.salesHistorydates?.map(({ date_Check }, i) => (
              <a
                key={i}
                href={`${backendUrl}/api/invoice/pdf/supply-waybill/${format(
                  new Date(date_Check),
                  "yyyy-MM-dd"
                )}/${salesID}`}
                target="blank"
                className="badge bg-primary p-3 p-cursor col-3 mx-3 my-3 text-decoration-none"
              >
                {format(new Date(date_Check), "dd MMM, yyyy")}
              </a>
            ))}
          </div>

          {data?.salesHistorydates?.length < 1 ? (
            <div className="d-flex justify-content-center text-center w-100 my-4">
              <NoTableItem queryParams={queryParams} />
            </div>
          ) : null}
        </div>

        <div className="d-flex justify-content-between px-3 align-items-center pagination">
          <p className="m-0">
            Showing {data?.startIndex + 1} to{" "}
            {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
            {data.count} entries
          </p>

          <ReactPaginate
            {...paginationOptions}
            pageCount={Math.ceil(data.count / queryParams.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={0}
            onPageChange={({ selected }) => {
              scrollToTop();
              setQueryParams({
                ...queryParams,
                page: selected + 1,
              });
            }}
            forcePage={queryParams.page - 1}
          />

          <ModalLoader show={isLoading} />
        </div>
      </Modal.Body>
    </Modal>
  );
}
