import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  //Utils,
} from "chart.js";
import { services } from "../../config";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { styleHelper } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import { Button, Form, Modal } from "react-bootstrap";
import { useState } from "react";
import useDebounce from "../../utils/hooks";
import queryString from "query-string";
import CachedIcon from "mdi-react/CachedIcon";
import Revenue from "../Overviews/Revenue";
import Income from "../Overviews/Income";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function getGradient(ctx, chartArea, color) {
  //background: linear-gradient(180deg, #E2E7F8 0%, rgba(255, 255, 255, 0) 100%);

  let gradient = ctx.createLinearGradient(255, 255, 255, 1);
  gradient.addColorStop(0, "#ffffff");
  gradient.addColorStop(1, color);

  return gradient;
}

export default function LineChart() {
  const { backendUrl } = useAuth();
  const initialFilterParams = {
    by: "year",
  };
  const [show, setShow] = useState(false);
  const [queryParams, setQueryParams] = useState({
    ...initialFilterParams,
  });
  const debouncedqueryParams = useDebounce(queryParams, 800);

  const getSalesOverview = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/dashboard/sales-overview?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        //  credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    let { data } = await response.json();

    if (styleHelper.isMobile) {
      data = data.slice(4, 10);
      console.log(data);
    }
    return data;
  };

  const { error, data, isSuccess, refetch, isFetching } = useQuery(
    ["GET_SALES_OVERVIEW", debouncedqueryParams],
    () => getSalesOverview(debouncedqueryParams),
    {
      // enabled: false,
      // cacheTime: 0,
      onSuccess: (data) => {
        // queryClient.removeQueries("LOGIN");
      },
    }
  );

  useEffect(() => {
    console.log(error);
  }, [error]);
  return (
    <div className="chart">
      <header className="section-header p-3 pe-4">
        <h2>
          Sales Overview{" "}
          <button
            title="Refresh"
            onClick={() => refetch()}
            className="btn text-primary"
          >
            <CachedIcon />
          </button>
        </h2>
        <div className="d-flex gap-2">
          <Button onClick={() => setShow(true)} variant="light-blue">
            Overview
          </Button>
          <Form.Select
            aria-label="Default select example"
            value={queryParams.by}
            onChange={(e) =>
              setQueryParams({
                ...queryParams,
                by: e.target.value,
              })
            }
          >
            <option value="year">This Year</option>
            <option value="7">Last 7 Days</option>
            <option value="28">Last 28 Days</option>
            <option value="90">Last 90 Days</option>
          </Form.Select>
        </div>
      </header>

      <div className="chart-holder ps-3">
        <Line
          options={{
            //  responsive: true,
            plugins: {
              legend: {
                position: "bottom",
                labels: {
                  fontColor: "#94A3B8",
                  fontSize: 12,
                  boxHeight: 8,
                  boxWidth: 8,
                  pointStyle: "circle",
                  usePointStyle: true,
                  textAlign: "center",
                },
                reverse: true,
              },
              title: {
                display: false,
              },
              tooltip: {
                backgroundColor: "#00000080",
                boxHeight: 8,
                boxWidth: 8,
                pointStyle: "circle",
                usePointStyle: true,
                boxPadding: 8,
              },
            },

            scales: {
              y: {
                grid: {
                  color: "#F4F7FA",
                },
                ticks: {
                  color: "#94A3B8",
                  stepSize: 100000000,
                  fontSize: 12,
                },
              },
              x: {
                grid: {
                  color: "#fff",
                  hoverColor: "#0F172A",
                },
                ticks: {
                  color: "#94A3B8",
                  fontSize: 12,
                },
              },
            },
          }}
          data={{
            labels: [...(isSuccess ? data.map((el) => el.date) : [])],
            datasets: [
              {
                label: "Income",
                data: [...(isSuccess ? data.map((el) => el.trans) : [])],
                backgroundColor: "#21D589",
                borderColor: "#21D589",
                borderRadius: 100,
                barThickness: 16,
                tension: 0.4,
                fill: false,
                // pointRadius: 0,
              },
              {
                label: "Revenue",
                data: [...(isSuccess ? data.map((el) => el.revenue) : [])],
                backgroundColor: function(context) {
                  const chart = context.chart;
                  const { ctx, chartArea } = chart;

                  if (!chartArea) {
                    // This case happens on initial chart load
                    return null;
                  }
                  return getGradient(ctx, chartArea, "#E2E7F8");
                },
                pointBackgroundColor: "#2463AE",
                borderColor: "#2463AE",
                borderRadius: 100,
                barThickness: 16,
                tension: 0.4,
                fill: "origin",
                // pointRadius: 0,
              },
            ],
          }}
        />
        <div className="blur" />
      </div>
      <OverviewModal show={show} setShow={setShow} />
    </div>
  );
}

export function OverviewModal({ show, setShow }) {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          <h2 className="h5">Revenue vs Income</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row justify-content-between">
          <div className="col-md-6  pe-5">
            <Revenue />
          </div>
          <div className="col-md-6  ps-5">
            <Income />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
