import { Table } from "react-bootstrap";
import { useAuth } from "../../hooks/useAuth";
import queryString from "query-string";
import { useState } from "react";
import useDebounce from "../../utils/hooks";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { paginationOptions } from "../../utils/helpers";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format } from "date-fns";

export default function Income(initialFilterParams = {}) {
  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const fetchExpenses = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/overview/income?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const {
    data = { count: 0, revenue: [] },
    /*   refetch,
    isFetching,
    isSuccess, */
  } = useQuery(
    ["INCOME_OVERVIEW", queryParams],
    () => fetchExpenses(queryParams),
    {
      keepPreviousData: true,
    }
  );

  return (
    <div>
      <div
        className="
    mb-3"
      >
        <h2 className="h6">Income</h2>
      </div>
      <Table responsive borderless striped className="product-table">
        <thead>
          <th>Customer</th>
          {/*  <th>Payment Type</th> */}
          <th>Installment</th>
          <th>Date</th>
        </thead>
        <tbody>
          {data.revenue.map((el, index) => (
            <tr key={index}>
              <td>{el.ShipTo}</td>
              {/*  <td>{el.Payment_Method}</td> */}
              <td>
                {currency(el.Installment, {
                  symbol: "",
                }).format()}
              </td>
              <td>
                {el.Trans_Date
                  ? format(new Date(el.Trans_Date), "dd MMM yyyy")
                  : "..."}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between px-3 align-items-center pagination">
        <div className="pagination_left">
          <select
            value={queryParams.limit}
            name="limit"
            className="form-select "
            onChange={(e) => handleSearchQueryChange(e)}
          >
            <option value="10">10 rows</option>
            <option value="20">20 rows</option>
            <option value="30">30 rows</option>
            <option value="40">40 rows</option>
          </select>
        </div>

        <ReactPaginate
          {...paginationOptions}
          pageCount={Math.ceil(data.count / queryParams.limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={0}
          onPageChange={({ selected }) => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            setQueryParams({
              ...queryParams,
              page: selected + 1,
            });
          }}
          forcePage={queryParams.page - 1}
        />
      </div>

      {/* <div className="summaryNew  mt-4">
        <div className="gridCont">
          <div className="gridChild gridChildBorderLeftBlue">
            <div className="gridChildLeft">
              <p className="gridChld1">
                {currency(data?.totalIncome, {
                  symbol: "",
                }).format()}
              </p>
              <p className="gridChld2">Total Expenses</p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
