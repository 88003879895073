import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { QueryClient, useIsFetching } from "react-query";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useEffectOnce } from "../utils/hooks";
import NavBar from "./NavBar";
import { useStoreActions } from "easy-peasy";
import { LoaderIcon } from "./Icons";
import OfflineCheck from "./OfflineCheck";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";

export default function MasterLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, backendUrl } = useAuth();
  const setItemMeasurements = useStoreActions(
    (actions) => actions.setItemMeasurements
  );
  const setDefaultCustomer = useStoreActions(
    (actions) => actions.setDefaultCustomer
  );

  const isFetching = useIsFetching();
  // Pull relevant Data
  const setUp = async () => {
    let response = await fetch(`${backendUrl}/api/auth/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    if (data?.isExpired) {
      toast.error("Account Expired, Contact Support");
      return navigate("/login", { replace: true });
    }
    // console.log(data);
    setItemMeasurements(data.itemMeasurements);
    setDefaultCustomer(data.defaultCustomer);

    document.title = `Invex ERP - ${user.company}`;
  };

  useEffectOnce(() => {
    setUp();
  });

  if (isEmpty(user)) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return (
    <>
      <NavBar />
      <Outlet />
      {isFetching ? (
        <div className="global-spinner">
          <LoaderIcon className="spin text-primary" />
        </div>
      ) : (
        <></>
      )}

      <footer className="d-flex py-3 align-items-center justify-content-center d-none">
        <p className="mb-0">
          ©{new Date().getFullYear()} Excellentbridge Technologies
        </p>
      </footer>
      <OfflineCheck />
    </>
  );
}
