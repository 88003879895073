import { Table } from "react-bootstrap";
import { useAuth } from "../../hooks/useAuth";
import queryString from "query-string";
import { useState } from "react";
import useDebounce from "../../utils/hooks";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { customerFullName, paginationOptions } from "../../utils/helpers";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format } from "date-fns";
import { appSettings } from "../../config";

export default function Revenue({ dateRange }) {
  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 40,
    reportType: "Detailed",
    isBatchStandard: appSettings.isBatchStandard,
    ...dateRange,
  });

  const [filterParams, setFilterParams] = useState({
    ...dateRange,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const fetchRevenue = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/sales-analysis?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const {
    data = { count: 0, sales: [] },
    /*   refetch,
    isFetching,
    isSuccess, */
  } = useQuery(
    ["REVENUE_OVERVIEW", queryParams],
    () => fetchRevenue(queryParams),
    {
      keepPreviousData: true,
    }
  );

  return (
    <div>
      <div
        className="
    mb-3"
      >
        <h2 className="h6">Revenue</h2>
      </div>
      <Table responsive borderless striped className="product-table">
        <thead>
          <th>Date</th>
          <th>Customer</th>
          <th>Item Code</th>
          <th>Item Name</th>
          <th>QTY</th>
          <th>Profit</th>
          <th>Subtotal</th>
        </thead>
        <tbody>
          {data.sales.map((el, index) => (
            <tr key={index}>
              <td>
                {el.Date_Log
                  ? format(new Date(el.Date_Log), "yyyy-MM-dd")
                  : "..."}
              </td>
              <td>
                {el?.customer?.LastName === "Walk-In"
                  ? `${el?.transaction?.ShipTo} (Walk-In)`
                  : customerFullName(el.customer)}
              </td>

              <td>{el.Bar_Code}</td>
              <td>{el.Item_Name}</td>

              <td>
                {currency(el.QTY, {
                  symbol: "",
                  precision: 1,
                }).format()}
              </td>
              <td>
                {currency(el.Profit, {
                  symbol: "",
                }).format()}
              </td>
              <td>
                {currency(el.SubTotal, {
                  symbol: "",
                }).format()}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between px-3 align-items-center pagination">
        <div className="pagination_left">
          <select
            value={queryParams.limit}
            name="limit"
            className="form-select "
            onChange={(e) => handleSearchQueryChange(e)}
          >
            <option value="10">10 rows</option>
            <option value="20">20 rows</option>
            <option value="30">30 rows</option>
            <option value="40">40 rows</option>
          </select>
        </div>

        <ReactPaginate
          {...paginationOptions}
          pageCount={Math.ceil(data.count / queryParams.limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={0}
          onPageChange={({ selected }) => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            setQueryParams({
              ...queryParams,
              page: selected + 1,
            });
          }}
          forcePage={queryParams.page - 1}
        />
      </div>

      {/*  <div className="summaryNew  mt-4">
        <div className="gridCont">
          <div className="gridChild gridChildBorderLeftBlue">
            <div className="gridChildLeft">
              <p className="gridChld1">
                {currency(data?.totalRevenue, {
                  symbol: "",
                }).format()}
              </p>
              <p className="gridChld2">Total Revenue</p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
