import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { useQuery } from "react-query";
import { services } from "../../config";
import lodash from "lodash";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { useAuth } from "../../hooks/useAuth";
import useDebounce from "../../utils/hooks";
import queryString from "query-string";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  responsive: true,
  cutout: `60%`,
  layout: {
    padding: {
      left: 24,
      right: 24,
      top: 24,
      bottom: 24,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      backgroundColor: "#00000080",
      boxHeight: 8,
      boxWidth: 8,
      pointStyle: "circle",
      usePointStyle: true,
      boxPadding: 8,
    },
    datalabels: {
      align: "end",
      anchor: "end",
      fontSize: 12,
      padding: 4,
      formatter: function(value, context) {
        // console.log(value, context.chart.data);
        let total = context.chart.data.datasets[0].data.reduce(
          (a, b) => a + b,
          0
        );
        return `${((value / total) * 100).toFixed(0)}%`;
      },
      // hoverColor: "#00000",
      listeners: {
        enter: function(context) {
          // Receives `enter` events for any labels of any dataset. Indices of the
          // clicked label are: `context.datasetIndex` and `context.dataIndex`.
          // For example, we can modify keep track of the hovered state and
          // return `true` to update the label and re-render the chart.
          context.hovered = true;
          return true;
        },
        leave: function(context) {
          // Receives `leave` events for any labels of any dataset.
          context.hovered = false;
          return true;
        },
      },
      color: function(context) {
        // Change the label text color based on our new `hovered` context value.
        return context.hovered ? "#000" : "#94A3B8";
      },
    },
  },
};

export default function TopSellingProduct() {
  const initialFilterParams = {
    by: "all-time",
  };
  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    ...initialFilterParams,
  });
  const debouncedqueryParams = useDebounce(queryParams, 800);

  const getTopSelling = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/dashboard/top-selling?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        //  credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { error, data, isSuccess } = useQuery(
    ["GET_TOP_SELLING_PRODUCT", debouncedqueryParams],
    () => getTopSelling(debouncedqueryParams),
    {}
  );

  useEffect(() => {
    console.log(error);
  }, [error]);

  return (
    <div className="info">
      <header className="section-header p-3 pe-4">
        <h2>Top Selling Products</h2>

        <Form.Select
          aria-label="Default select example"
          value={queryParams.by}
          onChange={(e) =>
            setQueryParams({
              ...queryParams,
              by: e.target.value,
            })
          }
        >
          <option value="all-time">All Time</option>
          {/*   <option value="year">This Year</option> */}
          <option value="7">Last 7 Days</option>
          <option value="28">Last 28 Days</option>
          <option value="90">Last 90 Days</option>
        </Form.Select>
      </header>

      {isSuccess && (
        <div className="pie-holder">
          <Doughnut
            plugins={[ChartDataLabels]}
            options={options}
            data={{
              labels: [
                ...(isSuccess
                  ? data.map(
                      (el) =>
                        `${lodash.truncate(el.Item_Name, {
                          length: 22,
                        })}`
                    )
                  : []),
              ],
              datasets: [
                {
                  data: [
                    ...(isSuccess
                      ? data.map((el, i, arr) => el.QuantitySold)
                      : []),
                  ],
                  backgroundColor: [
                    //  "#062B68",
                    "#07327A",
                    "#083A8F",
                    "#0A45A9",
                    "#1052C0",
                    "#2563EB",
                    "#3B82F6",
                    "#60A5FA",
                    "#93C5FD",
                    "#BFDBFE",
                    "#DBEAFE",
                  ],
                  borderColor: [
                    // "#062B68",
                    "#07327A",
                    "#083A8F",
                    "#0A45A9",
                    "#1052C0",
                    "#2563EB",
                    "#3B82F6",
                    "#60A5FA",
                    "#93C5FD",
                    "#BFDBFE",
                    "#DBEAFE",
                  ],
                  borderWidth: 1,
                  hoverOffset: 4,
                },
              ],
            }}
          />
          <div className="background" />
        </div>
      )}
    </div>
  );
}
