import Modal from "react-bootstrap/Modal";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import Select from "react-select";

import { useAuth } from "../../hooks/useAuth";
import { sendFormData, fetchActionsUtil } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import Avatar from "../utils/Avatar";

const initials = {
  profilePics: "",
  Name: "",
  AccessLavel: "",
  Warehouse: "",
  username: "",
  Department: "",
  email: "",
  Branch: "",
  Staff_ID: "",
};

const allDepartment = [
  "",
  "Admin",
  "Accountant",
  "Cashier",
  "Content Management",
  "Contracts",
  "HR",
  "Sales",
  "Shout Out",
  "Store",
  "Warehouse",
  "Production",
];

const alLevels = ["", "High", "Low"];

const branches = ["", "HQ", "Small Branch"];

const EditUserAccountModal = (props) => {
  const { backendUrl } = useAuth();

  const [inputData, setInputData] = useState(initials);
  //   const [userData, setUserData] = useState({});

  const fechIt = useCallback(async () => {
    const getData = await fetchActionsUtil(
      `${backendUrl}/api/users/${props.userId}`,
      "GET"
    );

    const {
      Name,
      AccessLavel,
      Warehouse,
      username,
      Department,
      email,
      Branch,
      Title,
    } = getData?.data;
    setInputData({
      Name: Name ? Name : "",
      AccessLavel: AccessLavel ? AccessLavel : "",
      Warehouse: Warehouse ? Warehouse : "",
      username: username ? username : "",
      Department: Department ? Department : "",
      email: email ? email : "",
      Branch: Branch ? Branch : "",
      profilePics: "",
      Staff_ID: props.userId,
      Title: Title ? Title : "",
    });
  }, [backendUrl, props.userId]);

  useEffect(() => {
    props.userId && fechIt();
  }, [props.userId, fechIt, props.show]);

  // console.log(inputData);

  // const { data, isFetching } = useQuery(
  //   ["FETCH_WAREHOUSE"],
  //   () => fetchActionsUtil(`${backendUrl}/api/warehouse`, "GET"),
  //   {
  //     keepPreviousData: true,
  //   }
  // );

  // const mergedWarehouse = useMemo(() => {
  //   return data?.warehouses?.map((el) => ({
  //     label: el.W_name,
  //     value: el.W_ID,
  //   }));
  // }, [data]);

  // const defaltWarehouse = useMemo(() => {
  //   try {
  //     return inputData?.Warehouse?.split(",")?.map((el) => ({
  //       // label: mergedWarehouse.find(({ value }) => value === el).label,
  //       // value: el,
  //       label: el,
  //       value: el,
  //     }));
  //   } catch (error) {}
  // }, [inputData?.Warehouse]);

  // console.log(defaltWarehouse);

  const OnChangeInput = (e) => {
    const { name, value, files } = e.target;
    if (name === "profilePics")
      return setInputData({ ...inputData, [name]: files[0] });
    setInputData({ ...inputData, [name]: value });
  };

  const editAccount = useMutation(
    (payload) =>
      sendFormData(`${backendUrl}/api/users/edit-user-account`, payload),
    {
      onSuccess: () => {
        props.refetch();
        toast.success("Account Updated Successfully");
        props.refetch();
        props.onHide();
      },
      onError: ({ message = "" }) => {
        toast.error(message);
      },
    }
  );

  const saveData = async (e) => {
    e.preventDefault();

    const { profilePics, ...rest } = inputData;

    if (typeof rest?.Warehouse === "string") {
    } else {
      rest.Warehouse = rest?.Warehouse?.map((d) => d?.value);
    }

    const formData = new FormData();
    for (const [key, value] of Object.entries(rest)) {
      formData.append(key, value);
    }

    if (profilePics) {
      formData.append(
        "profilePics",
        profilePics,
        `${rest.Staff_ID}_profilePics`
      );
    }

    if (
      await ConfirmDialog({
        title: "Edit Profile",
        description: `Are you sure you want to EDIT ${
          props.modalName
        }'s Profile`,
      })
    ) {
      editAccount.mutate(formData);
    }
  };

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <h6>Edit Details</h6>
              <div role="button" className="text-lg" onClick={props.onHide}>
                x
              </div>
            </div>
            <p className="text-muted">{`Edit ${props.modalName}'s Details`}</p>
          </div>
          <form className="mt-3" onSubmit={saveData}>
            <div className="row">
              <div className="col-2 p-3">
                <Avatar
                  onFileUpload={OnChangeInput}
                  image={inputData.profilePics}
                  name={inputData.Name}
                  style={{ width: "7rem", height: "7rem" }}
                  nameInput="profilePics"
                  userId={props.userId}
                />
              </div>
              <div className="col-10 p-3">
                <div className="row">
                  <div className="col-6">
                    {/*  */}
                    <div className="mb-3">
                      <label className="col-form-label fw-bold">
                        Full Name
                      </label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          value={inputData.Name}
                          name="Name"
                          onChange={OnChangeInput}
                        />
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="col-form-label fw-bold">
                        Access Level
                      </label>
                      <div>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={inputData.AccessLavel}
                          name="AccessLavel"
                          onChange={OnChangeInput}
                        >
                          {alLevels.map((a, i) => (
                            <option key={i} value={a}>
                              {a}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {/*  */}
                    <div className="mb-3">
                      <label className="col-form-label fw-bold">Email</label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          value={inputData.email}
                          name="email"
                          onChange={OnChangeInput}
                        />
                      </div>
                    </div>
                    {/*  */}
                  </div>
                  {/* Second side inputs */}
                  <div className="col-6">
                    <div className="mb-3">
                      <label className="col-form-label fw-bold">Title</label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          value={inputData.Title}
                          name="Title"
                          onChange={OnChangeInput}
                        />
                      </div>
                    </div>
                    {/*  */}
                    <div className="mb-3 d-none">
                      <label className="col-form-label fw-bold">
                        User Name
                      </label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          value={inputData.username}
                          name="username"
                          onChange={OnChangeInput}
                          readOnly
                        />
                      </div>
                    </div>
                    {/*  */}
                    <div className="mb-3">
                      <label className="col-form-label fw-bold">
                        Department
                      </label>
                      <div>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={inputData.Department}
                          name="Department"
                          onChange={OnChangeInput}
                        >
                          {allDepartment.map((d, i) => (
                            <option key={i} value={d}>
                              {d}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {/*  */}

                    {/* {inputData.Department === "Warehouse" && (
                      <div className="mb-3 row">
                        <label className="col-sm-4 col-form-label fw-bold text-nowrap">
                          Choose Warehouse
                        </label>
                        <div>
                          <Select
                            closeMenuOnSelect={true}
                            isLoading={isFetching}
                            isMulti
                            isSearchable={true}
                            defaultValue={defaltWarehouse}
                            onChange={(select) => {
                              setInputData({
                                ...inputData,
                                Warehouse: select,
                              });
                            }}
                            options={mergedWarehouse}
                          />
                        </div>
                      </div>
                    )} */}
                    {/*  */}

                    <div className="mb-3">
                      <label className="col-form-label fw-bold">Branch</label>
                      <div>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={inputData.Branch}
                          name="Branch"
                          onChange={OnChangeInput}
                        >
                          {branches.map((d, i) => (
                            <option key={i} value={d}>
                              {d}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {/*  */}
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <div className="d-flex justify-content-end m-4">
        <button className="btn bg print mx-4" onClick={props.onHide}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={saveData}>
          Update details
        </button>
      </div>
    </Modal>
  );
};

export default EditUserAccountModal;
